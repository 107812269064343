import React from "react"
import styled from "styled-components"

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")
require("swiper/components/pagination/pagination.scss")
require("swiper/components/scrollbar/scrollbar.scss")

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const SlideshowContainer = styled.div`
  position: relative;
  --swiper-theme-color: white;
  --swiper-navigation-size: 1.25rem;

  .swiper-button-prev {
    left: calc(5vw + 1.25rem);
  }

  .swiper-button-next {
    right: calc(5vw + 1.25rem);
  }

  .swiper-pagination {
    top: calc(100vw / 16 * 9 - 30px);
    height: 1.5rem;
  }
`

const Slideshow = (props) => {
  return (
    <SlideshowContainer>
      <Swiper
        navigation
        loop
        speed={1000}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {React.Children.map(props.children, (slide) => {
          return <SwiperSlide>{slide}</SwiperSlide>
        })}
      </Swiper>
    </SlideshowContainer>
  )
}

export default Slideshow
