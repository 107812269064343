import React from "react"

import Layout from "../layouts/layout"
import Hero from "../components/hero"
import Slideshow from "../components/slideshow"
import Seo from "../components/seo"

import Text from "../components/text"
import Headline from "../components/headline"
import Block from "../components/block"
import Logo from "../components/logo"

const IndexPage = () => (
  <Layout>
    <Seo title="ECM Musicstore im Ostseebad Ahrenshoop auf dem Darss" />

    <Block id="ecmmusicstore">
      <Logo />
      <Text>
        <h3>EDITION OF CONTEMPORARY MUSIC</h3>
      </Text>
    </Block>

    <Slideshow>
      <Hero filename="ecmmusicstore-25.jpg" copyright="Foto: © Hagen Hansen" />
      <Hero filename="ecmmusicstore-20.jpg" copyright="Foto: © Hagen Hansen" />
    </Slideshow>

    <Block>
      <Headline text="ECM Musicstore" primary />
      <Text>
        <h3>
          Präsentation und Verkauf von Musikproduktionen des Labels ECM Records
        </h3>
        <p>
          Die Einzigartigkeit der Produktionen von ECM Records setzt Maßstäbe in
          Aufnahmequalität, musikalischem Anspruch und Sensibilität. Die
          Leistung des Gründers Manfred Eicher erfährt seit Jahrzehnten von der
          musikalischen Fachwelt und Künstlern gleichermaßen große Anerkennung
          und Auszeichnung. Diese Produktionen haben Hagen Hansen veranlasst
          sich mit dem Schaffenswerk von ECM näher zu beschäftigen und dieses
          der Öffentlichkeit vorzustellen.
        </p>
        <p>
          Das heutige mediale Angebot erfordert aus technischer und inhaltlicher
          Sicht ein hohes Maß an Aufmerksamkeit vom Konsumenten. Selbst für
          leidenschaftliche Musikliebhaber sind diese Werke häufig schwer zu
          finden. Die Bedeutung von dem individuellen musikalischen Schaffen
          äusserst vielseitiger Künstler stellt ein hohes Kulturgut dar und
          sollte einem breiten Publikum zugänglich sein. Diesen Prozess zu
          unterstützen ist Hagen Hansen ein persönliches Anliegen.
        </p>
      </Text>
    </Block>

    <Slideshow>
      <Hero
        text=""
        filename="ecmmusicstore-04.jpg"
        copyright="Foto: © Hagen Hansen"
      />
      <Hero
        text=""
        filename="ecmmusicstore-01.jpg"
        copyright="Foto: © Hagen Hansen"
      />
    </Slideshow>

    <Block id="events">
      <Headline text="Events" primary />
      <Text>
        <h3>SELECTED</h3>
        <p>
          Neuerscheinungen und Empfehlungen vorgestellt von Hagen Hansen
          <br />
          16.05.2024 · 16-18 Uhr
          <br />
          Eintritt frei{" "}
          <a href="mailto:info@ecmmusicstore.de?subject=Reservierung SELECTED 16.05.2024">
            {" "}
            [Reservierung erforderlich]
          </a>
        </p>
        <br />
        <h3>SELECTED</h3>
        <p>
          Neuerscheinungen und Empfehlungen vorgestellt von Hagen Hansen
          <br />
          Termin folgt · 16-18 Uhr
          <br />
          Eintritt frei{" "}
          <a href="mailto:info@ecmmusicstore.de?subject=Reservierung SELECTED 13.06.2024">
            {" "}
            [Reservierung erforderlich]
          </a>
        </p>
      </Text>
    </Block>

    <Block id="review">
      <Headline text="Review" primary />
    </Block>

    <Slideshow>
      <Hero
        filename="ecmmusicstore-26.jpg"
        subtitle="Chris Hooson (Dakota Suite) und Hagen Hansen"
        copyright="Foto: © Hagen Hansen"
      />
      <Hero
        filename="ecmmusicstore-27.jpg"
        subtitle="Chris Hooson (Dakota Suite) und Hagen Hansen"
        copyright="Foto: © Hagen Hansen"
      />
      <Hero
        filename="ecmmusicstore-um.jpg"
        subtitle="Uli Masuth - Politisches Kabarett 30.12.2023"
        copyright="Foto: © Andreas Gantenhammer"
      />
      <Hero
        filename="ecmmusicstore-mw.png"
        subtitle="Marcin Wasilewski - Piano solo 19.06.2022"
        copyright="Foto: © Bartek Barczyk"
      />
      <Hero
        filename="ecmmusicstore-mw-live.png"
        subtitle="Marcin Wasilewski - Piano solo 19.06.2022"
        copyright="Foto: © Matthias Gärtner / Hagen Hansen"
      />
      <Hero
        filename="ecmmusicstore-mw_hh.jpg"
        subtitle="Hagen Hansen mit Marcin Wasilewski"
        copyright="Foto: © Bernd Goltings / Hagen Hansen"
      />
      <Hero
        filename="ecmmusicstore-24.jpg"
        subtitle="Nils Landgren, Hagen Hansen, Marcin Wasilewski"
        copyright="Foto: © Nils Landgren / Hagen Hansen"
      />
      <Hero
        filename="ecmmusicstore-22.jpg"
        subtitle="Horia Dumitrache - Kammermusiktage Ahrenshoop 2022"
        copyright="Foto: Peter Suha"
      />
      <Hero
        filename="ecmmusicstore-jh.jpg"
        subtitle="Julia Hülsmann - Piano solo 17.08.2019"
        copyright="Foto: © Devile Sermakas"
      />
      <Hero
        filename="ecmmusicstore-sm_hh.jpg"
        subtitle="Stephan Micus zu Besuch bei Hagen Hansen"
        copyright="Foto: © Hagen Hansen"
      />
      <Hero
        filename="ecmmusicstore-cn.png"
        subtitle="Cristian Niculescu - Klavier Klassik 11.10.2021"
        copyright="Foto: © Aldi Stoicoviciu"
      />
      <Hero
        filename="ecmmusicstore-zb.jpg"
        subtitle="Zsuzsa Balint - Klavier Klassik 11.10.2021"
        copyright="Foto: © Frank Jerke"
      />
      <Hero
        filename="ecmmusicstore-naf.jpg"
        subtitle="Niculescu and Friends 2019/2021"
        copyright="Foto: © Hagen Hansen"
      />
    </Slideshow>

    <Block id="newsletter">
      <Headline text="Newsletter" primary />
      <Text>
        <p>
          Wünschen Sie weitere Informationen zu Veranstaltungen und
          Neuerscheinungen im ECM Musicstore können Sie sich zum Newsletter
          anmelden (Versand 1-2 x pro Jahr): 
          <a href="mailto:newsletter@ecmmusicstore.de?subject=Anmelden">
            newsletter [at] ecmmusicstore.de
          </a>
          . Mit dem Absenden dieser E-Mail erklären Sie sich mit den 
          <a href="/privacy">Datenschutzbedingungen</a> einverstanden.         
        </p>{" "}
      </Text>
    </Block>

    <Hero
      text=""
      filename="ecmmusicstore-02.jpg"
      copyright="Foto: © Hagen Hansen"
    />

    <Block>
      <Headline text="CD, Vinyl, Streamingdienst oder Download?" primary />
      <Text>
        <p>
          Die Entscheidung zwischen Tonträger oder digitaler Musikdatei wirft
          Fragen auf: Nicht jeder Musikliebhaber besitzt noch einen
          Plattenspieler oder ein CD-Laufwerk. Digital abrufbare
          Musikproduktionen ermöglichen den Verzicht auf notwendige Hardware zum
          Abspielen von Musikmedien. Gleichzeitg eröffnen Streamingdienste den
          Zugang zu einem scheinbar unerschöpflichen Musikangebot.{" "}
        </p>
        <p>
          Für eine bewußte Auswahl von Künstlern und Label sowie der Pflege
          einer eigenen Musikbibliothek bietet der Musikgenuss mit CD oder Vinyl
          einzigartige Vorteile. Die hundertprozentige Verfügbarkeit und der
          Zugang zu weiteren inhaltlichen Informationen in Form von Cover und
          Booklet ergänzen das haptische Erlebnis. Auch wird durch die jeweilige
          Wahl im Moment des Hörens einem diffusen Musikkonsum grundsätzlich
          entgegengewirkt. Die Nutzung von Cloud- und Streamingdiensten
          verursacht neben dem Verlust einer musikalischen Identität auch
          Abhängigkeit von Dienstleistern sowie laufende Kosten. Zusätzlich
          fördern Streamingdienste eine ungünstige Energiebilanz, da für deren
          Betrieb Rechenzentren und Onlinedienste nötig sind.{" "}
        </p>
        <p>
          Was meinen Sie zu diesem Thema? Ihre Meinung zu der Verfügbarkeit von
          Musikproduktionen interessiert Hagen Hansen und können sie ihm gern
          per E-Mail an 
          <a href="mailto:info@ecmmusicstore.de?subject=Meinung zum Musikmedium">
            {" "}
            info [at] ecmmusicstore.de
          </a>
           kundtun. Mit dem Absenden dieser E-Mail erklären Sie sich mit den 
          <a href="/privacy">Datenschutzbedingungen</a> einverstanden.
        </p>
      </Text>
    </Block>

    <Hero
      text=""
      filename="ecmmusicstore-03.jpg"
      copyright="Foto: © Hagen Hansen"
    />

    <Block id="openingtime">
      <Headline text="Opening Time" primary />
      <Text>
        <h3>Montag und Donnerstag 10-12 Uhr</h3>
        <p>Dynamisch an weiteren Tagen sowie nach Terminvereinbarung.</p>
      </Text>
    </Block>

    <Hero
      text=""
      filename="ecmmusicstore-07.jpg"
      copyright="Foto: © Hagen Hansen"
    />

    <Block id="contact">
      <Headline text="Contact" primary />
      <Text>
        <p>
          ECM Musicstore
          <br />
          Paetowweg 6<br />
          D- 18347 Ostseebad Ahrenshoop
          <br />
          Deutschland
          <br />
          Telefon: <a href="tel:+4938220667717">+49 38220 667717</a>
          <br />
          Fax: +49 38220 667722
          <br />
          E-Mail: 
          <a href="mailto:info@ecmmusicstore.de">info [at] ecmmusicstore.de</a>
        </p>
      </Text>
    </Block>
  </Layout>
)

export default IndexPage
